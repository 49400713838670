<template>
  <PocSlider minheight="70px" btntype="primary">
    <el-form
      :inline="true"
      :model="formInline"
      label-position="top"
      class="el-form elformInputBox minWidthInput"
    >
      <el-form-item label="HFM Code">
        <el-select
          v-model="formInline.data.hfmCode"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in hfmCodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 1">
        <el-select
          v-model="formInline.data.level1"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange(1)"
        >
          <el-option
            v-for="item in levelList1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 2">
        <el-select
          v-model="formInline.data.level2"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange(2)"
        >
          <el-option
            v-for="item in levelList2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 3">
        <el-select
          v-model="formInline.data.level3"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange(3)"
        >
          <el-option
            v-for="item in levelList3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Join Date" v-if="pageTab !=='resign'">
        <el-date-picker
          v-model="formInline.data.joinDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="joinDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Last Working Date">
        <el-date-picker
          v-model="formInline.data.lastWorkingDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="lastWorkingDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="English Name">
        <el-input
          placeholder="请输入"
          v-model="formInline.data.englishName"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Chinese Name">
        <el-input
          placeholder="请输入"
          v-model="formInline.data.chineseName"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Working Location">
        <el-select
          v-model="formInline.data.location"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in locationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </PocSlider>
</template>

<script setup>
import { ref, reactive, onMounted, computed, defineProps, toRefs, inject } from "vue";
import { getHfmCodeList } from '@/api/organization';
import { getLevel1List, getLevel2List } from '@/api/itData';
import { getLevel3 ,getWorkLocation } from "@/api/personnelRequisition";
import { getStaffFlow } from '@/api/staff'

const props = defineProps({
  pageTab:String
})
const {pageTab} = toRefs(props)
const formInline = inject('formInline')
const hfmCodeList = ref([])
const levelList1 = ref([])
const levelList2 = ref([])
const levelList3 = ref([])
const locationList = ref([])
const getFormInlineData = () =>{
  getHfmCodeList().then(({code,data})=>{
    if(code === 200){
      hfmCodeList.value = data
    }
  })
  getLevel1List().then(({code, data}) => {
    if (code == 200) {
      levelList1.value = data;
    }
  })
  getLevel2List('').then(({code, data}) => {
    if (code == 200) {
      levelList2.value = data;
    }
  })
  getLevel3('').then(({code, data}) => {
    if (code == 200) {
      levelList3.value = data;
    }
  })
  getWorkLocation().then(({code, data}) => {
    if (code == 200) {
      locationList.value = data;
    }
  })
}
const getLevelList2 = () => {
  let level1Id = formInline.data.level1 ? formInline.data.level1 : ''
  getLevel2List(level1Id).then(({code, data}) => {
    if (code == 200) {
      levelList2.value = data;
    }
  })
}
const getLevelList3 = () => {
  let parmas = {
    level2Id: formInline.data.level2
  }
  getLevel3(parmas).then(({code, data}) => {
    if (code == 200) {
      levelList3.value = data;
    }
  })
}
const levelChange = (val)=> {
  if (val == 1) {
    formInline.data.level2 = '';
    formInline.data.level3 = '';
    getLevelList2();
    getLevelList3();
  } else if (val == 2) {
    formInline.data.level3 = '';
    getLevelList3()
  } else {

  }
}


const joinDateChange = (val) =>{
  if (val) {
    formInline.data.joinDateStart = val[0]
    formInline.data.joinDateEnd = val[1]
  } else {
    formInline.data.joinDateStart = ''
    formInline.data.joinDateEnd = ''
  }
}
const lastWorkingDateChange = (val) =>{
  if (val) {
    formInline.data.lastWorkingDateStart = val[0]
    formInline.data.lastWorkingDateEnd = val[1]
  } else {
    formInline.data.lastWorkingDateStart = ''
    formInline.data.lastWorkingDateEnd = ''
  }
}
onMounted(()=>{
  getFormInlineData()
})
defineExpose({
  formInline
})
</script>

<style lang="scss" scoped>
::v-deep .poc-slider-btns{
  //padding-top: 28px;
}

</style>
