<template>
  <div class="resign">
    <div class="resign__container__topOperate">
      <div></div>
      <div class="employees__container__topOperate__rightInput">
        <el-button
          @click="downloadTableList"
          size="mini"
          v-loading.fullscreen.lock="fullscreenLoading"  role="downloadResign" v-permission
        >下载列表数据</el-button
        >
      </div>
    </div>
    <div class="resign__container__table">
      <PocTable
        v-loading="pageConfig.loading"
        :data="resignPageData.tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :max-height="1000000000000000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @sort-change="sortChange"
        ref="resignContainerTable"
        stripe
      >
        <!--                @selection-change="handleSelectionChange"-->
        <!--                @selection-change="handleSelectionChange"-->
        <!--        <el-table-column type="selection" width="50" />-->
        <el-table-column
          key="englishName"
          prop="englishName"
          label="English Name"
          width="150"
        >
          <template slot-scope="scope">
            <span
              >{{ scope.row.englishName }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          key="chineseName"
          prop="chineseName"
          label="Chinese Name"
          width="150"
        >
          <template slot-scope="scope">
            <span
              >{{ scope.row.chineseName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in resignColumn"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>
        <el-table-column label="操作" width="false" fixed="right" align="center">
          <template slot-scope="scope"> </template>
        </el-table-column>
      </PocTable>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, getCurrentInstance, inject, ref } from "vue";
import TableMixin from '@/views/employee/Hooks/TableMixin'
let { pageConfig, pageChange, pageSizeChange, currentPage } =
  TableMixin(queryApi)
import { resignColumn } from '@/views/IT_Data/columnConfig/toBeEmployed'
import { useRouter } from '@/utils/webApi'
const Router = useRouter()
import { useRefs } from '@/utils/webApi'
import { setItem } from '@/utils/storage'
import { getEmployeesApi, getEmployeesApiInfo } from "@/views/IT_Data/Hooks/getEmployeesApi";
import { downLoadStaffList } from "@/api/staff";
import { downLoadFileByBlob } from "@/utils/util";
import { downLoadStaffListByStatus } from "@/api/itData";
let vue = getCurrentInstance().proxy

const $refs = useRefs()
const { Message } = $PCommon
const fullscreenLoading = ref(false)

const resignPageData = reactive({
  tableData: [],
  selectVal: [],
  pageConfig,
  formLine: {
    KeyWord: ''
  }
})
const formInline = inject('formInline')

//接口请求
function queryApi() {
  const data = {
    type: 'resign',
    status:'2',
    pageSize: pageConfig.pageSize,
    pageNum: pageConfig.pageNum,
    keyWord: resignPageData.formLine.KeyWord,
    ...formInline.data
  }
  getEmployeesApiInfo(data, resignPageData, $refs.resignContainerTable)
}
const search = ()=> {
  pageConfig.pageNum = 1
  queryApi()
}
defineExpose({
  search
})
onMounted(() => {
  queryApi()
})
const handleSelectionChange = (val) => {
  resignPageData.selectVal = val
}
//查看列表详情
const chatOnTheJobItem = (row) => {
  // setItem('tabsClickData__yc', row)
  // const obj = JSON.stringify({
  //   jumpFlag: 'goDetail',
  //   candidateInformationId: row.candidateInformationId,
  //   idNumber: row.idNumber
  // })
  // Router.push({
  //   path: '/employees/employeesItemDetails',
  //   query: {
  //     jumpParams: obj
  //   }
  // })
}
const sortChange = (column)=>{
  if(column.order === 'ascending'){
    formInline.data.orderColumn=column.prop
    formInline.data.orderAsc=true
  }else if(column.order === 'descending'){
    formInline.data.orderColumn=column.prop
    formInline.data.orderAsc=false
  }else {
    formInline.data.orderColumn=''
    formInline.data.orderAsc=''
  }
  queryApi()
}
const downloadTableList = () =>{
  const data = {
    type: 'resign',
    status:'2',
    ...formInline.data
  }
  fullscreenLoading.value = true
  downLoadStaffListByStatus(data).then(res=>{
    fullscreenLoading.value = false
    if(res){
      downLoadFileByBlob(res)
    }
  })
}
</script>

<style lang="scss" scoped>
.resign__container__topOperate {
  box-sizing: border-box;
  padding: 16px 20px 9px 20px;
  display: flex;
  justify-content: flex-end;
  &__rightInput {
    text-align: center;
    :deep(.el-input) {
      margin-right: 12px;
      .el-input__inner {
        width: 200px;
        height: 30px;
      }
    }
  }
}
.resign__container__table {
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  &--textHover {
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
  &__narrowButtonText {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
</style>
