<template>
  <div>
    <div class="employees__container__topOperate">
      <div class="employees__container__topOperate__leftDownload">
        <el-dropdown v-if="false">
          <el-button type="primary">
            下载<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              role="downloadAll"
              v-permission
              @click.native="downloadAll"
              >下载全部文件</el-dropdown-item
            >
            <el-dropdown-item
              role="downloadContract"
              v-permission
              @click.native="downloadContract"
              >下载合同</el-dropdown-item
            >
            <el-dropdown-item
              role="downloadIncome"
              v-permission
              @click.native="downloadStaffTemplate"
              >下载收入证明</el-dropdown-item
            >
            <el-dropdown-item
              role="downloadCertificateEmployment"
              v-permission
              @click.native="downloadCertificateOfIncumbency"
              >下载在职证明</el-dropdown-item
            >
            <!-- 没找着role是什么意思，待确认 -->
            <el-dropdown-item
              role="downloadCertificateEmployment"
              v-permission
              @click.native="downloadPersonalInformation"
              >下载个人信息</el-dropdown-item
            >
            <el-dropdown-item

              @click.native="downloadOffer"
            >下载Offer信息</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="employees__container__topOperate__rightInput">
        <el-button
          @click="downloadTableList"
          size="mini"
          v-loading.fullscreen.lock="fullscreenLoading"  role="downloadToBeEmployed" v-permission
        >下载列表数据</el-button
        >
      </div>
    </div>
    <div class="employees__container__table">
      <PocTable
        v-loading="pageConfig.loading"
        :data="onTheJobPageData.tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :max-height="1000000000000000000"
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @sort-change="sortChange"
        ref="employeesContainerTable"
        stripe
      >
        <el-table-column type="selection" width="50" />
        <el-table-column
          key="englishName"
          prop="englishName"
          label="English Name"
          width="120"
        >
          <template slot-scope="scope">
            <!-- <span
              class="employees__container__table--textHover"
              @click="chatOnTheJobItem(scope.row)"
              >{{ scope.row.englishName }}</span
            > -->
            {{ scope.row.englishName }}
          </template>
        </el-table-column>

        <el-table-column
          key="chineseName"
          prop="chineseName"
          label="Chinese Name"
          width="120"
        >
          <template slot-scope="scope">
            <!-- <span
              class="employees__container__table--textHover"
              @click="chatOnTheJobItem(scope.row)"
              >{{ scope.row.chineseName }}
            </span> -->
            {{ scope.row.chineseName }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in onTheJobColumn"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>
        <!-- <el-table-column
          key="noticeItAndAdmin"
          prop="noticeItAndAdmin"
          label="是否已通知IT/ADMIN"
          width="true"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.emailSent"
              placement="top"
              :disabled="
                scope.row.noticeItAndAdmin == '否' || !scope.row.emailSent
              "
            >
              <span class="spanWidth">{{ scope.row.noticeItAndAdmin }}</span>
            </el-tooltip>
          </template>
        </el-table-column> -->
       <!-- <el-table-column key="hrbpOwner" prop="hrbpOwner" label="HRBP Owner">
         <template slot-scope="scope">
           <span>{{ scope.row.hrbpOwner }}</span>
         </template>
       </el-table-column> -->
        <el-table-column label="操作" min-width="100" fixed="right" align="center">
          <template slot-scope="scope" v-if="false">
            <el-button
              type="text"
              size="mini"
              role="edit"
              v-permission
              @click="editOnTheJobItem(scope.row)"
              class="employees__container__table__narrowButtonText"
              >编辑</el-button
            >
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <svg-icon
                  icon="ellipsis"
                  class="tablePointSvgClass"
                  title="更多"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  role="transfer"
                  v-permission
                  @click.native="chatOnTheJobItem(scope.row)"
                  >查看</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </PocTable>
    </div>
    <TurnsOutInformationDialog
      ref="TurnsOutInformationDialogRef"
      @onConfirm="onPersonnelSelectionConform"
      @onStaging="onPersonnelSelectionStaging"
    />
    <quit-dialog ref="quitDialogRef" @close="queryApi" />
    <becomeRegularWorkerDialog
      ref="becomeRegularWorkerDialogRef"
      @onConfirm="becomeRegularWorkerConfirm"
    />
    <promotionTableAuditDialog
      ref="promotionTableAuditDialogRef"
      @back="queryApi"
    />
    <renewTableDialog ref="renewTableDialogRef" @close="queryApi" />
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, getCurrentInstance, inject, ref } from "vue";
import TurnsOutInformationDialog from '@/views/employee/dialog/TurnsOutInformationDialog'
import quitDialog from '@/views/employee/dialog/quitDialog'
import becomeRegularWorkerDialog from '@/views/employee/dialog/becomeRegularWorkerDialog'
import promotionTableAuditDialog from '@/views/employee/dialog/promotionTableAuditDialog'
import renewTableDialog from '@/views/employee/dialog/renewTableDialog'
import TableMixin from '@/views/employee/Hooks/TableMixin'
let { pageConfig, pageChange, pageSizeChange, currentPage } =
  TableMixin(queryApi)
import { onTheJobColumn } from '@/views/IT_Data/columnConfig/toBeEmployed'
import { getEmployeesApi, getEmployeesApiInfo } from "@/views/IT_Data/Hooks/getEmployeesApi";
import { downloadStaff } from '@/views/employee/Hooks/downloadStaff'
import { useRouter } from '@/utils/webApi'
const Router = useRouter()
import { useRefs } from '@/utils/webApi'
import { saveLeaveForm, saveTransferUser } from '@/api/quto'
import {
  checkWorkFlow,
  exportStaffList,
  savePositiveInfo
} from '@/api/employees'
import { setItem } from '@/utils/storage'
import { downLoadStaffList } from "@/api/staff";
import { downLoadFileByBlob } from "@/utils/util";
import { downLoadStaffListByStatus } from "@/api/itData";
let vue = getCurrentInstance().proxy

const $refs = useRefs()
const { Message } = $PCommon
const fullscreenLoading = ref(false)

const onTheJobPageData = reactive({
  tableData: [],
  selectVal: [],
  pageConfig,
  formLine: {
    KeyWord: ''
  }
})
const isFlowing = (val) => {
  if (val.status) {
    //val.status === '已发起，待提交' || val.status === '待提交'
    return false
  } else {
    return true
  }
}
const isRenew = (val) => {
  if (val.status) {
    return val.status === '续签中'
  } else {
    return true
  }
}
const formInline = inject('formInline')
//接口请求
function queryApi() {
  const data = {
    changeManagementType: 'others',
    myType: 'myToDo',
    status:'3',
    type: 'toBeEmployed',
    pageSize: pageConfig.pageSize,
    pageNum: pageConfig.pageNum,
    keyWord: onTheJobPageData.formLine.KeyWord,
    ...formInline.data
  }
  getEmployeesApiInfo(data, onTheJobPageData, $refs.employeesContainerTable)
}
const search = ()=> {
  pageConfig.pageNum = 1
  queryApi()
}
defineExpose({
  search
})
onMounted(() => {
  queryApi()
})
const handleSelectionChange = (val) => {
  onTheJobPageData.selectVal = val
}
const sortChange = (column)=>{
  if(column.order === 'ascending'){
    formInline.data.orderColumn=column.prop
    formInline.data.orderAsc=true
  }else if(column.order === 'descending'){
    formInline.data.orderColumn=column.prop
    formInline.data.orderAsc=false
  }else {
    formInline.data.orderColumn=''
    formInline.data.orderAsc=''
  }
  queryApi()
}
/*
===========================下载============================
 */
//下载常量
const downloadConst = {
  contract: '1',
  certificateOfIncumbency: '2',
  proofOfIncome: '3',
  all: 4,
  personalInformation: '5',
  offer:'6'
}
//下载收入证明
const downloadStaffTemplate = () => {
  downloadStaff({ flag: downloadConst.proofOfIncome }, onTheJobPageData)
}
const downloadContract = () => {
  downloadStaff({ flag: downloadConst.contract }, onTheJobPageData)
}
const downloadCertificateOfIncumbency = () => {
  downloadStaff(
    { flag: downloadConst.certificateOfIncumbency },
    onTheJobPageData
  )
}
//下载全部Staff List
const DownloadStaffList = () => {
  exportStaffList()
}
const downloadAll = () => {
  downloadStaff({ flag: downloadConst.all }, onTheJobPageData)
}

const downloadPersonalInformation = () => {
  downloadStaff({ flag: downloadConst.personalInformation }, onTheJobPageData)
}
const downloadTableList = () =>{
  const data = {
    changeManagementType: 'others',
    myType: 'myToDo',
    type: 'toBeEmployed',
    status:'3',
    ...formInline.data
  }
  fullscreenLoading.value = true
  downLoadStaffListByStatus(data).then(res=>{
    fullscreenLoading.value = false
    if(res){
      downLoadFileByBlob(res)
    }
  })
}
const downloadOffer = () => {
  downloadStaff({ flag: downloadConst.offer }, onTheJobPageData)
}
/*
===========================下载end============================
 */

//编辑列表项
const editOnTheJobItem = (row) => {
  setItem('tabsClickData__yc', row)
  Router.push({
    path: '/employees/employeesItemEdit',
    query: {
      jumpFlag: 'edit',
      candidateInformationId: row.candidateInformationId
    }
  })
}
//查看列表详情
const chatOnTheJobItem = (row) => {
  return;
  setItem('tabsClickData__yc', row)
  const obj = JSON.stringify({
    jumpFlag: 'goDetail',
    candidateInformationId: row.candidateInformationId,
    idNumber: row.idNumber
  })
  Router.push({
    path: '/employees/employeesItemDetails',
    query: {
      jumpParams: obj
    }
  })
}
/*
=========================调岗===============================
 */
// 存储当前调岗的id
let OutGoingId = ''
//调岗常量
const postTransferConst = {
  //调岗提交
  submit: 1,
  //调岗暂存
  staging: 2
}
//调岗
const postTransfer = (row) => {
  setItem('tabsClickData__yc', row)
  OutGoingId = row.candidateInformationId
  $refs.TurnsOutInformationDialogRef.show({
    id: row.candidateInformationId
  })
}
//调岗请求接口
const postTransferAPI = (val, flag) => {
  const message = flag === postTransferConst.submit ? '调岗提交' : '调岗暂存'
  const data = {
    hcIsBroughtOut: val.hcIsBroughtOut,
    id: OutGoingId,
    saveType: flag,
    userId: ''
  }
  if (val.selectVal && val.selectVal.length) {
    data.userId = val.selectVal[0].id.toString()
  }
  saveTransferUser(data).then((res) => {
    if (res && res.code === 200) {
      $refs.TurnsOutInformationDialogRef.close()
      Message.success(`${message}成功`)
      queryApi()
    }
  })
}
//调岗暂存的数据
const onPersonnelSelectionStaging = (val) => {
  postTransferAPI(val, postTransferConst.staging)
}
//调岗返回的数据
const onPersonnelSelectionConform = (val) => {
  postTransferAPI(val, postTransferConst.submit)
}
/*
========================调岗end===========================
 */

/*
=========================离职==========================
 */
const quit = (row) => {
  $refs.quitDialogRef.show({
    flag: 'quit',
    id: row.candidateInformationId
  })
}
//离职提交
const onQuitDialogConfirm = (val) => {
  // saveLeaveForm(val).then((res) => {
  //   if (res && res.code === 200) {
  //     Message.success('离职提交成功')
  //     this.cancel()
  //   }
  // })
}
//离职保存
const onQuitDialogSave = (val) => {
  // console.log(val)
}
/*
========================离职end===========================
 */
/*
========================转正=============================
 */
let becomeRegularWorkerId = ''
const becomeRegularWorker = (val) => {
  setItem('tabsClickData__yc', val)
  becomeRegularWorkerId = val.candidateInformationId
  $refs.becomeRegularWorkerDialogRef.show({
    personName: `${val.englishName}`,
    id: val.candidateInformationId
  })
}
const becomeRegularWorkerConfirm = (val) => {
  if (val) {
    const data = {
      id: becomeRegularWorkerId,
      lineManager: val.lineManager,
      lineManagerEmail: val.lineManagerEmail,
      probationEndDate: val.probationEndDate
    }
    savePositiveInfo(data).then((res) => {
      if (res && res.code === 200) {
        Message.success(`已发送，待反馈`)
        queryApi()
      }
    })
  }
}
const beyondPositiveDate = (row) => {
  let employmentConfirmationDate = row.employmentConfirmationDate
  if (employmentConfirmationDate) {
    let employmentConfirmationDateUnix = vue
      .$dayjs(employmentConfirmationDate)
      .unix()
    let nowDataUnix = vue.$dayjs(new Date()).unix()
    return nowDataUnix < employmentConfirmationDateUnix
  } else {
    // 没有转正日期直接显示转正按钮
    return true
  }
}
/*
========================转正end===========================
 */

/*
=======================晋升===============================
 */

const promotion = (val) => {
  if (val) {
    setItem('tabsClickData__yc', val)
    const data = {
      id: val.candidateInformationId
    }
    checkWorkFlow(data).then((res) => {
      if (res) {
        if (val.flow) {
          val.flag = 1
          $refs.promotionTableAuditDialogRef.show(val)
        } else {
          val.flag = 0
          $refs.promotionTableAuditDialogRef.show(val)
        }
      }
    })
  }
}
/*
=======================晋升end===========================
 */

/*
========================续签============================
 */
const renew = (row) => {
  row.title = '手动发起续签'
  row.flag = 'renew'
  row.content = '是否要手动发起该员工的合同续签流程，并向LM发送续签确认?'
  $refs.renewTableDialogRef.show(row)
}

/*
====================续签end=============================
 */
</script>

<style lang="scss" scoped>
.spanWidth {
  display: block;
  width: 100px;
  text-align: center;
}
.employees__container__topOperate {
  box-sizing: border-box;
  padding: 16px 20px 9px 20px;
  display: flex;
  justify-content: space-between;
  &__rightInput {
    display: flex;
    :deep(.el-input) {
      margin-right: 12px;
      .el-input__inner {
        width: 200px;
        height: 30px;
      }
    }
  }
}
.employees__container__table {
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  &--textHover {
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
  &__narrowButtonText {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
</style>
