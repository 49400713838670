<template>
  <div class="employees">
    <div class="employees__topHeader">
      <div class="employees__topHeader__leftTitle">员工列表</div>
      <div class="employees__topHeader__middleTab">
        <el-radio-group v-model="pageTab" @change="pageTabChange" size="small">
          <el-radio-button label="toBeEmployed">待入职 ({{
            Store.getters.itData.fteCount3
          }})</el-radio-button>
          <el-radio-button label="onTheJob">在职({{
              Store.getters.itData.fteCount1
            }})</el-radio-button>
          <el-radio-button label="resign" >已离职({{
              Store.getters.itData.fteCount2
            }})</el-radio-button>
          <el-radio-button label="changeManagement" disabled>异动管理</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flexBet" style="width: 260px;display: flex;justify-content: end;">
        <el-button @click="reset" class="resetButton" v-show="pageTab !== 'changeManagement'">
          <i class="el-icon-refresh-left buttonrefreshSpan"></i>
        </el-button>
        <!-- <el-button @click="dataUpdate" v-permission role="csDataUpdate" v-show="pageTab === 'onTheJob'">数据更新</el-button> -->
        <!-- v-permission -->
        <el-button role="downloadAll" v-permission @click="DownloadFTEList" size="mini"
          v-show="pageTab !== 'changeManagement'">下载全部数据</el-button>
        <el-button type="primary" @click="search" v-show="pageTab !== 'changeManagement'">搜索</el-button>
      </div>
    </div>
    <div class="employees__listForm">
      <employeesListForm v-if="pageTab !== 'changeManagement'" :pageTab="pageTab" />
    </div>
    <div class="employees__container">
      <component ref="pagesRef" :is="dynamicalComponent[pageTab]" />
    </div>
    <dataUpdateDialog ref="dataUpdateDialogRef" />
  </div>
</template>
<script>
import { removeItem } from '@/utils/storage'

export default {
  beforeRouteLeave: function (to, from, next) {
    let path = ['/employees/hired', '/employees/employeesItemEdit']
    if (!path.includes(to.path)) {
      // 离开员工列表路由 删除所有本地缓存的数据
      removeItem('pageTab__yc')
      removeItem('changeManagement__yc')
      removeItem('ChangeManagementTabs__yc')
      removeItem('saveClickRow__yc')
      removeItem('staffInfoCount__yc')
      removeItem('tabsClickData__yc')
    }
    next()
  }
}
</script>
<script setup>
import { ref, reactive, Ref, onMounted, provide } from "vue";
import onTheJob from './pages/employee/onTheJob.vue'
import toBeEmployed from './pages/employee/toBeEmployed.vue'
import resign from './pages/employee/resign.vue'
import changeManagement from './pages/employee/changeManagement.vue'
import employeesListForm from "./components/employeesListForm.vue";
import { getItem, setItem } from '@/utils/storage'
import { useRefs, useStore } from "@/utils/webApi";
import { exportFTEList } from "@/api/itData";
import dataUpdateDialog from "@/views/employee/dialog/dataUpdateDialog";
const Store = useStore()
const $refs = useRefs()
//页面标签名字
let pageTab = ref('toBeEmployed')
let dynamicalComponent = reactive({
  onTheJob,
  toBeEmployed,
  resign,
  changeManagement
})

const getPageTab = () => {
  let pageTab__yc = getItem('pageTab__yc')
  if (pageTab__yc) {
    pageTab.value = pageTab__yc
  }
}
getPageTab()
const pageTabChange = () => {
  setItem('pageTab__yc', pageTab.value)
  reset()
}

const pagesRef = ref(null)
const search = () => {
  if (pagesRef) {
    pagesRef.value.search()
  }
}
const formInline = reactive({
  data: {
    hfmCode: '',
    level1: '',
    level2: '',
    level3: '',
    joinDateStart: '',
    joinDateEnd: '',
    process: '',
    chineseName: '',
    englishName: '',
    lastWorkingDate: '',
    lastWorkingDateEnd: '',
    lastWorkingDateStart: '',
    notify: '',
    orderColumn: '',
    orderAsc: '',
  }
})
const reset = () => {
  formInline.data = {
    hfmCode: '',
    level1: '',
    level2: '',
    level3: '',
    joinDateStart: '',
    joinDateEnd: '',
    process: '',
    chineseName: '',
    englishName: '',
    lastWorkingDateEnd: '',
    lastWorkingDateStart: '',
    notify: '',
    orderColumn: '',
    orderAsc: '',
  }
  search()
}
provide('formInline', formInline)
//下载全部数据
const DownloadFTEList = () => {
  exportFTEList()
}

const dataUpdate = () => {
  $refs.dataUpdateDialogRef.show()
}
</script>

<style lang="scss" scoped>
.employees {
  &__topHeader {
    width: 100%;
    height: 69px;
    background: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-between;

    &__leftTitle {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303133;
    }

    &__middleTab {
      :deep(.el-radio-group) {
        .el-radio-button {
          .el-radio-button__inner {
            width: 100px;
            height: 30px;
          }
        }
      }
    }
  }

  &__listForm {
    background: #ffffff;
    padding: 0 20px;
  }

  &__container {
    margin-top: 15px;
    box-sizing: border-box;
    position: relative;
    background: #ffffff;
  }
}
</style>
